<template>
  <div class="calendar">
    <CalendarHeader :formattedMonthYear="formattedMonthYear" @change-month="changeMonth"/>

    <div class="calendar-body">
      <div class="day-names">
        <span v-for="day in daysOfWeek" :key="day">{{ day }}</span>
      </div>
      <div class="days">
        <span v-for="blank in leadingBlanks" :key="blank" class="day blank"></span>

        <CalendarDay
            v-for="day in daysInMonth"
            :key="day.date"
            :day="day"
            :isToday="day.isToday"
            :isCurrentMonth="day.isCurrentMonth"
            :hasInjection="isInjectionDay(day.date)"
            :injectionUnits="getInjectionUnits(day.date)"
            :injection="getInjection(day.date)"
            @update-injection="handleInjectionUpdate"
            @delete-injection="removeInjection"
            @update-messages="$emit('update-messages', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CalendarHeader from "@/components/Injection/Calendar/CalendarHeader.vue";
import CalendarDay from "@/components/Injection/Calendar/CalendarDay.vue";
import translator from "@/utils/translator";
import {ref, computed, watch} from "vue";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  isSameMonth,
  isToday
} from "date-fns";
import getInjections from "@/composables/injection/getInjections";
import {getCurrentDate} from "@/utils/getCurrentDate";

export default {
  components: {CalendarHeader, CalendarDay},
  props: {
    injections: Array
  },
  emits: ["update-messages"],
  setup(props, {emit}) {
    const today = getCurrentDate();
    const currentDate = ref(new Date(today.year, today.month - 1, today.day));
    const loadedInjections = ref([...props.injections]);
    const loadedMonths = ref(new Set([getCurrentMonthKey(today)]));

    const daysOfWeek = computed(() =>
        ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
            .map(day =>
                translator.t(`${day}_SHORT`)
            )
    );

    const months = computed(() =>
        [
          "JANUARY",
          "FEBRUARY",
          "MARCH",
          "APRIL",
          "MAY",
          "JUNE",
          "JULY",
          "AUGUST",
          "SEPTEMBER",
          "OCTOBER",
          "NOVEMBER",
          "DECEMBER"
        ].map(month => translator.t(month))
    );

    const formattedMonthYear = computed(() => {
      const monthIndex = currentDate.value.getMonth();
      const year = currentDate.value.getFullYear();
      return `${months.value[monthIndex]} ${year}`;
    });

    const daysInMonth = computed(() => getDaysInMonth(currentDate.value));

    const leadingBlanks = computed(() => {
      const firstDayOfWeek = startOfMonth(currentDate.value).getDay();
      return Array.from({length: firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1});
    });

    const changeMonth = async direction => {
      currentDate.value = direction > 0 ? addMonths(currentDate.value, 1) : subMonths(currentDate.value, 1);
      const {month, year} = {
        month: currentDate.value.getMonth() + 1,
        year: currentDate.value.getFullYear()
      };
      await updateInjectionsForMonth(month, year);
    };

    const updateInjectionsForMonth = async (month, year) => {
      const monthKey = getCurrentMonthKey({month, year});
      if (!loadedMonths.value.has(monthKey)) {
        try {
          const result = await getInjections(month, year);
          addNewInjections(result);
          loadedMonths.value.add(monthKey);
        } catch {
        }
      }
    };

    const isInjectionDay = date => loadedInjections.value.some(inj => inj.created === date);
    const getInjectionUnits = date => findInjection(date)?.numberOfUnits ?? null;
    const getInjection = date => findInjection(date);
    const findInjection = date => loadedInjections.value.find(inj => inj.created === date);

    const handleInjectionUpdate = injections => emit("update-injection", injections);

    watch(
        () => props.injections,
        newInjections => addNewInjections(newInjections),
        {immediate: true, deep: true}
    );

    function addNewInjections(newInjections) {
      loadedInjections.value = [
        ...loadedInjections.value,
        ...newInjections.filter(inj => !loadedInjections.value.some(loadedInj => loadedInj.id === inj.id))
      ];
    }

    function getCurrentMonthKey({month, year}) {
      return `${year}-${String(month).padStart(2, "0")}`;
    }

    function getDaysInMonth(date) {
      const start = startOfMonth(date);
      const end = endOfMonth(date);
      return eachDayOfInterval({start, end}).map(date => ({
        date: format(date, "yyyy-MM-dd"),
        day: format(date, "d"),
        isCurrentMonth: isSameMonth(date, currentDate.value),
        isToday: isToday(date)
      }));
    }

    const removeInjection = (injectionId) => {
      loadedInjections.value = loadedInjections.value.filter(inj => inj.id !== injectionId);
      emit('update-injection', loadedInjections.value);
    };

    return {
      daysOfWeek,
      formattedMonthYear,
      daysInMonth,
      leadingBlanks,
      changeMonth,
      isInjectionDay,
      getInjectionUnits,
      getInjection,
      handleInjectionUpdate,
      removeInjection
    };
  }
};
</script>

<style scoped>
.calendar {
  width: 100%;
  margin: auto;
}

.calendar-body {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5em;
}

.day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.blank {
  background-color: transparent;
  color: #ccc;
  cursor: default;
}
</style>
