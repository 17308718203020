import { API_BASE_URL } from '@/constants/constants';

async function deleteAccount() {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/user', {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    if (response.status === 204) {
        return {};
    }
}

export default deleteAccount
