import {API_BASE_URL} from '@/constants/constants';
import {getCurrentDate} from "@/utils/getCurrentDate";

async function getInjections(month = null, year = null) {
    if (month === null || year === null) {
        const currentDate = getCurrentDate();
        month = currentDate.month
        year = currentDate.year
    }

    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/injections/' + month + '-' + year, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.json();
}

export default getInjections
