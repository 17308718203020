<template>
  <div class="row mb-3">
    <div class="col-md-4 offset-md-4">
      <div class="mb-3">
        <label for="dateFromInput" class="form-label">{{ $translator.t('DATE_FROM') }}</label>
        <input type="date" class="form-control" id="dateFromInput" v-model="date_from" required>
      </div>

      <div class="mb-3">
        <label for="dateToInput" class="form-label">{{ $translator.t('DATE_TO') }}</label>
        <input type="date" class="form-control" id="dateToInput" v-model="date_to" required>
      </div>

      <button type="submit"
              class="btn btn-primary"
              @click="getStrokes()"
              :disabled="this.date_from === '' || this.date_to === ''">
        {{ $translator.t('SHOW_STROKES') }}
      </button>
    </div>
  </div>

  <p v-if="errors.message" class="error">{{ errors.message }}</p>
</template>

<script>
import {formatDate} from "@/utils/dateFormatter";
import translator from "@/utils/translator";
import getStrokes from "@/composables/stroke/getStrokes";

export default {
  data() {
    return {
      date_from: '',
      date_to: '',
      isButtonDisabled: false,
      errors: {},
    }
  },
  methods: {
    formatDate,
    getStrokes() {
      if (!this.validateInputDates()) {
        this.errors.message = translator.t('DATES_MISSING_MESSAGE')
        return
      }
      getStrokes({
        from: this.formatDate(this.date_from),
        to: this.formatDate(this.date_to),
      })
          .then(result => {
            this.errors = {}
            this.$emit('get-strokes', result)
          })
          .catch(err => console.log(err.message))
    },
    validateInputDates() {
      return !(this.date_from === null ||
          this.date_from === '' ||
          this.date_to === null ||
          this.date_to === '');
    },
  },
}
</script>

<style scoped>
.error {
  text-align: center;
  color: red;
}
</style>
