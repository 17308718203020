<template>
  <div class="modal fade"
       :id="'detailsInjectionModal_' + injection.id"
       tabindex="-1"
       aria-labelledby="detailsInjectionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="detailsInjectionModalLabel">
            {{ $translator.t('DETAILS') }} {{ injection.created }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $translator.t('DATE') }}: <b>{{ injection.created }}</b></p>
          <p>{{ $translator.t('UNITS') }}: <b>{{ injection.numberOfUnits }}</b>j.</p>
          <p class="faster_injection" v-if="injection.fasterInjection">
            {{ $translator.t('REASON') }}: <b>{{ injection.reason }}</b>
          </p>
          <p v-else>{{ $translator.t('REASON') }}: <b>{{ injection.reason }}</b></p>
          <p>{{ $translator.t('STOCK') }}: <b>{{ injection.stock.company }}</b></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $translator.t('OK') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    injection: Object
  },
  methods: {},
}
</script>
