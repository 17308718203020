import { API_BASE_URL } from '@/constants/constants';

async function patchUserSinglePortion(data = {}) {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/change-single-portion', {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData)
    }

    return response.json();
}

export default patchUserSinglePortion
