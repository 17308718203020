function isTokenExpired(token) {
    if (!token) {
        return true;
    }
    try {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        const exp = decoded.exp * 1000;
        return Date.now() > exp;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
}

export {isTokenExpired};
