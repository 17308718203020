<template>
  <div>
    <div class="modal fade" id="reasonModal" tabindex="-1" aria-labelledby="reasonModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reasonModalLabel">{{ $translator.t('REASONS_MODAL_HEADER') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body reasons">
            <h2 class="fs-5">
              <span v-if="reason_id">{{ $translator.t('EDIT') }}</span>
              <span v-else>{{ $translator.t('ADD') }}</span>
              {{ $translator.t('REASON') }}
            </h2>

            <p v-if="valid_success.reason_message" class="valid-success">{{ valid_success.reason_message }}</p>

            <div class="mb-4">
              <div class="mb-3">
                <input type="text"
                       class="form-control"
                       id="reasonTitleInput"
                       :placeholder="$translator.t('TITLE')"
                       v-model="reason_title"
                       required>
                <p v-if="errors.reason_title" class="valid-error">{{ errors.reason_title }}</p>
              </div>

              <div class="mb-3">
                <div class="form-check">
                  <label class="form-check-label" for="reasonIsMainInput">{{ $translator.t('SET_MAIN') }}</label>
                  <input class="form-check-input"
                         type="checkbox"
                         value=""
                         id="reasonIsMainInput"
                         v-model="reason_is_main"
                         :disabled="reasons.length === 0 || (reason_id && original_reason.reason_is_main)">
                </div>
                <p v-if="errors.reason_is_main" class="valid-error">{{ errors.reason_is_main }}</p>
              </div>

              <div class="mb-3">
                <div class="btn-group" role="group">
                  <div v-if="reason_id">
                    <button type="submit" @click="patchReasons(reason_id)" class="btn btn-primary btn-sm">
                      {{ $translator.t('SAVE') }}
                    </button>
                  </div>
                  <div v-else>
                    <button type="submit" @click="postReasons" class="btn btn-success btn-sm">
                      {{ $translator.t('ADD') }}
                    </button>
                  </div>

                  <button type="submit" @click="clearReasonForm" class="btn btn-secondary btn-sm">
                    {{ $translator.t('CLEAR') }}
                  </button>
                </div>
              </div>
            </div>

            <hr>

            <div class="mb-3">
              <table class="table">
                <tbody>
                <tr v-for="reason in reasons" :key="reason.id">
                  <td class="align-middle">
                    <span v-if="reason.isMain"><u><b>
                      <span v-if="reason.title === 'REASON_PREVENTION'">{{ $translator.t('REASON_PREVENTION') }}</span>
                      <span v-else>{{ reason.title }}</span>
                    </b></u></span>
                    <span v-else>
                      <span v-if="reason.title === 'REASON_PREVENTION'">{{ $translator.t('REASON_PREVENTION') }}</span>
                      <span v-else>{{ reason.title }}</span>
                    </span>
                  </td>
                  <td class="align-middle reason-btn-right">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle btn-sm"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                        {{ $translator.t('MORE') }}
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <button type="button"
                                  class="dropdown-item"
                                  @click="initializeUpdateReason(reason.id, reason.title, reason.isMain)">
                            {{ $translator.t('EDIT') }}
                          </button>
                        </li>
                        <li v-if="!reason.isMain">
                          <button type="button" class="dropdown-item" @click="deleteReasons(reason.id)">
                            {{ $translator.t('DELETE') }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getReasons from "@/composables/reason/getReasons";
import deleteReasons from "@/composables/reason/deleteReasons";
import postReasons from "@/composables/reason/postReasons";
import patchReasons from "@/composables/reason/patchReasons";
import translator from "@/utils/translator";

export default {
  props: {
    reasons: Array,
  },
  data() {
    return {
      reason_id: null,
      reason_title: null,
      reason_is_main: false,
      original_reason: {
        reason_id: null,
        reason_title: null,
        reason_is_main: null,
      },
      errors: {},
      valid_success: {}
    };
  },
  methods: {
    postReasons() {
      postReasons({
        title: this.reason_title,
        isMain: this.reason_is_main
      })
          .then(() => {
            getReasons()
                .then(result => {
                  this.$emit('update-reasons', result);
                  this.clearReasonForm();
                  this.valid_success.reason_message = translator.t('REASON_ADDED');
                  this.errors = {}
                })
                .catch(err => console.log(err.message));
          })
          .catch(err => {
            this.errors.reason_title = err
          });
    },
    patchReasons(id) {
      patchReasons(id, {
        title: this.reason_title,
        isMain: !!this.reason_is_main
      })
          .then(() => {
            getReasons()
                .then(result => {
                  this.$emit('update-reasons', result);
                  this.clearReasonForm();
                  this.valid_success.reason_message = translator.t('REASON_EDITED');
                  this.errors = {}
                })
                .catch(err => console.log(err.message));
          })
          .catch(err => {
            this.errors.reason_title = err
          });
    },
    deleteReasons(reasonId) {
      deleteReasons(reasonId)
          .then(() => {
            getReasons()
                .then(result => {
                  this.$emit('update-reasons', result);
                  this.valid_success.reason_message = translator.t('REASON_DELETED');
                })
                .catch(err => console.log(err.message));
          })
          .catch(err => console.log(err.message));
    },
    initializeUpdateReason(id, title, is_main) {
      this.reason_id = id;
      this.reason_title = title === 'REASON_PREVENTION' ? this.$translator.t('REASON_PREVENTION') : title;
      this.reason_is_main = is_main;

      this.original_reason.reason_id = id;
      this.original_reason.reason_title = title;
      this.original_reason.reason_is_main = is_main;

      this.valid_success.reason_message = null;
    },
    clearReasonForm() {
      this.reason_id = null;
      this.reason_title = null;
      this.reason_is_main = false;
      this.valid_success.reason_message = null;
    },
  },
  computed() {
    if (this.reasons.length === 0) {
      this.reason_is_main = true
    }
  }
};
</script>

<style scoped>
.reasons {
  text-align: left;
}

.reason-btn-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.valid-success {
  font-weight: bold;
  color: green;
}

.valid-error {
  font-weight: bold;
  color: red;
}
</style>
