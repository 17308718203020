<template>
  <div class="calendar-header">
    <button @click="changeMonth(-1)">❮</button>
    <span>{{ formattedMonthYear }}</span>
    <button @click="changeMonth(1)">❯</button>
  </div>
</template>

<script>
export default {
  props: {
    formattedMonthYear: String,
  },
  emits: ['change-month'],
  methods: {
    changeMonth(direction) {
      this.$emit('change-month', direction);
    }
  }
};
</script>

<style scoped>
.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
