<template>
  <div>
    <h1>
      {{ $translator.t('INJECTION') }}
      <button type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#addInjectionModal">
        +
      </button>
    </h1>

    <InjectionForm
        :reasons="reasons"
        @update-injections="updateInjections"
        @update-reasons="updateReasons"
        @update-messages="updateMessages"
    />

    <InjectionCalendar
        :injections="injections"
        @update-injection="updateInjections"
        @update-messages="updateMessages"
    />

    <div v-if="valid_success" class="alert alert-success mt-3">{{ valid_success }}</div>
  </div>
</template>

<script>
import ReasonModal from "@/components/Reason/ReasonModal.vue";
import InjectionForm from "@/components/Injection/InjectionForm.vue"
import getInjections from "@/composables/injection/getInjections";
import getReasons from "@/composables/reason/getReasons";
import InjectionCalendar from "@/components/Injection/Calendar/InjectionCalendar.vue";

export default {
  components: {
    ReasonModal,
    InjectionForm,
    InjectionCalendar,
  },
  data() {
    return {
      injections: [],
      reasons: [],
      valid_success: '',
    }
  },
  methods: {
    updateReasons(updatedReasons) {
      this.reasons = updatedReasons;
    },
    updateInjections(updatedInjections) {
      this.injections = updatedInjections;
    },
    updateMessages(updatedMessages) {
      this.valid_success = updatedMessages;
    },
  },
  created() {
    getInjections()
        .then(result => this.injections = result)
        .catch(err => console.log(err.message))
    getReasons()
        .then(result => {
          this.reasons = result
          if (this.reasons.length === 0) {
            this.reason_is_main = true
          }
        })
        .catch(err => console.log(err.message))
  },
};
</script>
