import { API_BASE_URL } from '@/constants/constants';

async function postInjections(data = {}) {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/injections', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const error = new Error('An error occurred');
        error.response = response;
        throw error;
    }

    if (response.status === 201) {
        return;
    }

    return response.json();
}

export default postInjections
