<template>
  <div>
    <h1>{{ $translator.t('SETTINGS') }}</h1>
    <div class="settings">
      <div class="row">
        <div class="col-md-8 offset-md-2">

          <div class="accordion" id="accordionSettings">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseSinglePortion" aria-expanded="true"
                        aria-controls="collapseSinglePortion">
                  {{ $translator.t('SINGLE_PORTION') }}
                </button>
              </h2>
              <div id="collapseSinglePortion" class="accordion-collapse collapse show"
                   data-bs-parent="#accordionSettings">
                <div class="accordion-body">
                  <label for="inputSinglePortion" class="form-label">
                    {{ $translator.t('CHANGE_SINGLE_PORTION') }}
                  </label>
                  <div class="input-group">
                    <input type="number"
                           class="form-control"
                           aria-label="Change single portion"
                           aria-describedby="inputSinglePortion"
                           v-model="singlePortion">
                    <button class="btn btn-secondary"
                            type="button"
                            id="inputSinglePortion"
                            @click="patchUserSinglePortion"
                            :disabled="singlePortion === user.singlePortion">
                      {{ $translator.t('SAVE') }}
                    </button>
                  </div>
                  <div v-if="errors.singlePortion" id="singlePortionHelpBlock" class="form-text help-block">
                    {{ errors.singlePortion }}
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFrequency" aria-expanded="false" aria-controls="collapseFrequency">
                  {{ $translator.t('FREQUENCY') }}
                </button>
              </h2>
              <div id="collapseFrequency" class="accordion-collapse collapse" data-bs-parent="#accordionSettings">
                <div class="accordion-body">
                  <label for="inputFrequency" class="form-label">
                    {{ $translator.t('CHANGE_FREQUENCY') }}
                  </label>
                  <div class="input-group">
                    <input type="number"
                           class="form-control"
                           aria-label="Change frequency"
                           aria-describedby="inputFrequency"
                           v-model="newFrequency">
                    <button class="btn btn-secondary"
                            type="button"
                            id="inputFrequency"
                            @click="patchUserFrequency"
                            :disabled="newFrequency === user.frequency">
                      {{ $translator.t('SAVE') }}
                    </button>
                  </div>
                  <div v-if="errors.frequency" id="frequencyHelpBlock" class="form-text help-block">
                    {{ errors.frequency }}
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseEmail" aria-expanded="false" aria-controls="collapseEmail">
                  {{ $translator.t('EMAIL') }}
                </button>
              </h2>
              <div id="collapseEmail" class="accordion-collapse collapse" data-bs-parent="#accordionSettings">
                <div class="accordion-body">
                  <label for="inputEmail" class="form-label">
                    {{ $translator.t('CHANGE_EMAIL') }}
                  </label>
                  <div class="input-group">
                    <input type="text"
                           class="form-control"
                           aria-label="Change email"
                           aria-describedby="inputEmail"
                           v-model="newEmail">
                    <button class="btn btn-secondary" type="button" id="inputEmail"
                            @click="patchUserEmail"
                            :disabled="newEmail === user.email">{{ $translator.t('SAVE') }}
                    </button>
                  </div>
                  <div v-if="errors.email" id="emailHelpBlock" class="form-text help-block">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePassword" aria-expanded="false" aria-controls="collapsePassword">
                  {{ $translator.t('PASSWORD') }}
                </button>
              </h2>
              <div id="collapsePassword" class="accordion-collapse collapse" data-bs-parent="#accordionSettings">
                <div class="accordion-body">
                  <PasswordForm
                      :errors="errors"
                      @password="updatePassword"
                  />

                  <div class="mb-3">
                    <button
                        class="btn btn-secondary"
                        @click="patchUserPassword">
                      {{ $translator.t('CHANGE_PASSWORD') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseDeleteAccount"
                        aria-expanded="false"
                        aria-controls="collapseDeleteAccount">
                  {{ $translator.t('DELETE_ACCOUNT') }}
                </button>
              </h2>
              <div id="collapseDeleteAccount"
                   class="accordion-collapse collapse"
                   data-bs-parent="#accordionSettings">
                <div class="accordion-body">

                  <button type="button"
                          class="btn btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteAccountModal">
                    {{ $translator.t('DELETE_ACCOUNT') }}
                  </button>

                  <div class="modal fade"
                       id="deleteAccountModal"
                       tabindex="-1"
                       aria-labelledby="deleteAccountModalLabel"
                       aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="deleteAccountModalLabel">
                            {{ $translator.t('DELETE_ACCOUNT') }}
                          </h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <h2 class="fs-5">{{ $translator.t('DELETE_ACCOUNT_CONFIRM_MESSAGE') }}</h2>
                          <p>{{ $translator.t('DELETE_ACCOUNT_ERASED_DATA_MESSAGE') }}</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" @click="deleteAccount">
                            {{ $translator.t('YES') }}
                          </button>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            {{ $translator.t('NO') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="changes_success" class="alert alert-success mt-3">{{ changes_success }}</div>
  </div>
</template>

<script>
import getUserDetails from "@/composables/user/getUserDetails";
import patchUserPassword from "@/composables/user/patchUserPassword";
import patchUserFrequency from "@/composables/user/patchUserFrequency";
import patchUserEmail from "@/composables/user/patchUserEmail";
import translator from "@/utils/translator";
import router from "@/router";
import PasswordForm from "@/components/Password/PasswordForm.vue";
import patchUserSinglePortion from "@/composables/user/patchUserSinglePortion";
import deleteAccount from "@/composables/user/deleteAccount";
import {closeModal} from "@/utils/closeModal";

export default {
  components: {PasswordForm},
  data() {
    return {
      user: {
        email: null,
        frequency: null,
        singlePortion: null,
      },
      singlePortion: null,
      newEmail: null,
      newFrequency: null,
      newPassword: null,
      confirmationPassword: null,
      isPasswordValid: null,
      hasValidConfirm: null,
      errors: {},
      changes_success: null
    };
  },
  methods: {
    updatePassword(data) {
      this.newPassword = data.newPassword;
      this.confirmationPassword = data.confirmationPassword;
      this.isPasswordValid = data.isPasswordValid;
      this.hasValidConfirm = data.hasValidConfirm;
    },
    patchUserEmail() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
          this.newEmail !== this.user.email &&
          this.newEmail.match(regexEmail)
      ) {
        patchUserEmail({
          email: this.newEmail
        })
            .then(() => {
              this.changes_success = translator.t('EMAIL_CHANGED_SUCCESS');
              this.errors.email = null
              this.user.email = this.newEmail
            })
            .catch(err => {
              this.errors.email = err
            })
      } else {
        this.errors.email = translator.t('EMAIL_CHANGED_FAILURE');
      }
    },
    patchUserFrequency() {
      if (this.newFrequency !== this.user.frequency) {
        patchUserFrequency({
          frequency: this.newFrequency
        })
            .then(() => {
              this.changes_success = translator.t('FREQUENCY_CHANGED_SUCCESS')
              this.errors.frequency = null
              this.user.frequency = this.newFrequency
            })
            .catch(err => {
              this.errors.frequency = err
            })
      }
    },
    patchUserSinglePortion() {
      if (this.singlePortion !== this.user.singlePortion) {
        patchUserSinglePortion({
          single_portion: this.singlePortion
        })
            .then(() => {
              this.changes_success = translator.t('SINGLE_PORTION_CHANGED_SUCCESS')
              this.errors.singlePortion = null
              this.user.singlePortion = this.singlePortion
            })
            .catch(err => {
              this.errors.singlePortion = err
            })
      }
    },
    patchUserPassword() {
      if (
          this.newPassword !== null &&
          this.confirmationPassword !== null &&
          this.newPassword === this.confirmationPassword
      ) {
        patchUserPassword({
          newPassword: this.newPassword,
          confirmPassword: this.confirmationPassword,
        })
            .then(() => {
              this.changes_success = translator.t('PASSWORD_CHANGED_SUCCESS')
              this.errors.password = null
              this.newPassword = null
              this.confirmationPassword = null
            })
            .catch(err => {
              this.errors.password = err
            })
      } else {
        this.errors.password = translator.t('PASSWORD_CHANGED_CONFIRM_FAILURE')
      }
    },
    deleteAccount() {
      deleteAccount()
          .then(() => {
            closeModal('deleteAccountModal')
            localStorage.removeItem('token');
            router.push('/login');
          })
          .catch(err => console.log(err))
    },
  },
  created() {
    getUserDetails()
        .then(result => {
          this.user = result;
          this.newEmail = result.email;
          this.newFrequency = result.frequency;
          this.singlePortion = result.single_portion;

          this.user.singlePortion = result.single_portion;
          this.user.email = result.email;
        })
        .catch(err => console.log(err.message));
  },
  watch: {
    newFrequency(value) {
      if (value <= 0) {
        this.newFrequency = null
      } else if (value !== null && value !== this.user.frequency) {
        this.newFrequency = Number(value).valueOf()
      }
    },
    singlePortion(value) {
      if (value <= 0) {
        this.singlePortion = null
      } else if (value !== null && value !== this.user.singlePortion) {
        this.singlePortion = Number(value).valueOf()
      }
    },
  },
};
</script>

<style scoped>
.help-block {
  color: red;
}

.settings {
  text-align: left;
}
</style>

