<template>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">{{ $translator.t('DATE') }}</th>
      <th scope="col">{{ $translator.t('REASON') }}</th>
      <th scope="col">{{ $translator.t('UNITS') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="injection in injections" :key="injection.id">

      <td>
        <button type="button"
                class="btn btn-link btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="'#detailsInjectionModal_' + injection.id">
          {{ injection.created }}
        </button>

        <InjectionDetailsModal
            :injection="injection"
        />
      </td>

      <td>
        {{ injection.reason }}
      </td>

      <td>
        <span class="faster_injection" v-if="injection.fasterInjection">{{ injection.numberOfUnits }}</span>
        <span v-else>{{ injection.numberOfUnits }}</span>
      </td>

    </tr>
    </tbody>
  </table>
</template>

<script>
import InjectionDetailsModal from "@/components/Stroke/InjectionDetailsModal.vue";

export default {
  components: {InjectionDetailsModal},
  props: {
    injections: Array
  },
  methods: {},
}
</script>
