import { API_BASE_URL } from '@/constants/constants';

async function registerUser(data = {}) {
    const response = await fetch(API_BASE_URL + '/api/user/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const error = new Error();
        error.response = response;
        try {
            error.message = (await response.json()) || error.message;
        } catch {}
        throw error;
    }

    if (response.status === 201) {
        return;
    }

    return response.json();
}

export default registerUser
