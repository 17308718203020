import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login.vue'
import Stock from "../views/Stock.vue";
import Injection from "../views/Injection.vue";
import Stroke from "../views/Stroke.vue";
import Panel from "../views/Panel.vue";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'injection',
        component: Injection,
        meta: {requiresAuth: true}
    },
    {
        path: '/stroke',
        name: 'stroke',
        component: Stroke,
        meta: {requiresAuth: true}
    },
    {
        path: '/stock',
        name: 'stock',
        component: Stock,
        meta: {requiresAuth: true}
    },
    {
        path: '/panel',
        name: 'panel',
        component: Panel,
        meta: {requiresAuth: true}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = localStorage.getItem('token');

    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else if (to.path === '/login' && isAuthenticated) {
        next('/');
    } else {
        next();
    }
});

export default router
