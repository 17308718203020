<template>
  <div :class="dayClasses">
    <button v-if="hasInjection"
            type="button"
            class="btn btn-link day-font day-full"
            data-bs-toggle="modal"
            :data-bs-target="'#detailsInjectionModal_' + injection.id">
      {{ day.day }}
    </button>

    <span v-else class="day-font">{{ day.day }}</span>

    <span v-if="hasInjection" class="units">{{ injectionUnits }}</span>
  </div>

  <InjectionDetailsModal
      v-if="hasInjection"
      :injection="injection"
      :id="'detailsInjectionModal_' + injection.id"
      @update-injection="$emit('update-injection', $event)"
      @delete-injection="handleDeleteInjection"
      @update-messages="$emit('update-messages', $event)"
  />
</template>

<script>
import {defineAsyncComponent} from "vue";

const InjectionDetailsModal = defineAsyncComponent(() => import("@/components/Injection/InjectionDetailsModal.vue"));

export default {
  components: {InjectionDetailsModal},
  props: {
    day: Object,
    isToday: Boolean,
    isCurrentMonth: Boolean,
    hasInjection: Boolean,
    injectionUnits: Number,
    injection: Object,
  },
  emits: ["update-injection", "delete-injection", "update-messages"],
  methods: {
    handleDeleteInjection(injectionId) {
      this.$emit('delete-injection', injectionId);
    },
  },
  computed: {
    dayClasses() {
      return [
        'day',
        {
          'day--outside-month': !this.isCurrentMonth,
          'day--today': this.isToday,
          'day--out-of-standard': this.hasInjection && this.injection && this.injection.isOutOfStandard,
          highlighted: this.hasInjection,
        }
      ];
    }
  }
};
</script>

<style scoped>
.day {
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.day-font {
  text-decoration: none;
  font-size: large;
  font-weight: bold;
}

.day--outside-month {
  background-color: transparent;
  color: #ccc;
  cursor: default;
}

.day--today {
  background-color: #d1f7d6;
  color: #007f00;
  font-weight: bold;
}

.highlighted {
  background-color: #d1e7ff;
}

.day--out-of-standard {
  background-color: #ffcccc;
  color: #ff0000;
  font-weight: bold;
}

.units {
  position: absolute;
  bottom: 0.2em;
  right: 0.2em;
  font-size: 0.8em;
  color: #003399;
}

.day:not(.highlighted) {
  cursor: default;
}

.day-full {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
</style>
