<template>
  <div>
    <h1>
      {{ $translator.t('STOCK') }}
      <button type="button"
              class="btn btn-success btn"
              data-bs-toggle="modal"
              data-bs-target="#addStockModal">
        +
      </button>
    </h1>

    <div v-if="this.errors.delete_stock" class="alert alert-danger mt-3">{{ this.errors.delete_stock }}</div>
    <div v-if="this.valid_success.update" class="alert alert-success mt-3">{{ this.valid_success.update }}</div>
    <div v-if="this.valid_success.delete" class="alert alert-success mt-3">{{ this.valid_success.delete }}</div>

    <StockForm
        :stock="edit_stock"
        @update-stocks="updateStocks"
        @update-errors="updateErrors"
        @update-valid-success="updateValidSuccess"
    />

    <StockList
        :stocks="stocks['items']"
        @update-stocks="updateStocks"
        @update-errors="updateErrors"
        @update-valid-success="updateValidSuccess"
        @delete-valid-success="deleteValidSuccess"
    />

    <div class="pagination">
      <Pagination
          :current_page="stocks.currentPage"
          :total_items="stocks.totalItems"
          :items_per_page="stocks.itemsPerPage"
          :total_pages="stocks.totalPages"
          @change-page="changePage"
      />
    </div>

  </div>
</template>

<script>
import getStocks from "@/composables/stock/getStocks";
import StockList from "@/components/Stock/StockList.vue";
import StockForm from "@/components/Stock/StockForm.vue";
import Pagination from "@/components/Common/Pagination.vue";

export default {
  components: {StockList, StockForm, Pagination},
  data() {
    return {
      stocks: {
        currentPage: null,
        totalItems: null,
        itemsPerPage: null,
        totalPages: null,
        items: []
      },

      edit_stock: {
        stock_id: null,
        stock_company: null,
        stock_date: null,
        stock_terminated_date: null,
        stock_units: null,
      },

      stock_company: null,
      stock_date: null,
      stock_terminated_date: null,
      stock_units: null,

      errors: {},
      valid_success: {},
    }
  },
  methods: {
    updateStocks(updatedStocks) {
      this.stocks = updatedStocks;
    },
    updateErrors(errors) {
      this.errors = {}
      this.errors.delete_stock = errors;
    },
    updateValidSuccess(success) {
      this.valid_success = {}
      this.valid_success.update = success;
    },
    deleteValidSuccess(success) {
      this.valid_success = {}
      this.valid_success.delete = success;
    },
    async fetchStocks(page = 1) {
      try {
        this.stocks = await getStocks(page);

        if (page === 1) {
          this.$router.replace({path: '/stock'});
        } else {
          this.$router.replace({path: '/stock', query: {page: page}});
        }

      } catch (error) {
        console.log(error.message);
      }
    },
    changePage(page) {
      this.fetchStocks(page)
    }
  },
  created() {
    const page = this.$route.params.page ? parseInt(this.$route.params.page) : 1;
    this.fetchStocks(page)
  }
};
</script>

<style>
.pagination {
  justify-content: center;
}
</style>
