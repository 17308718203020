<template>
  <div class="modal fade"
       :id="'detailsStockModal_' + stock.id" tabindex="-1"
       aria-labelledby="detailsStockModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="detailsStockModalLabel">{{ stock.company }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!--                  <p>Recommended to place an order: <u><b>{{ stock.RECOMMDED  }}</b></u></p>-->
          <p>{{ $translator.t('AVAILABLE_PORTIONS') }}: <b>{{ stock.availablePortions }}</b></p>
          <p v-if="!stock.allUnitsUsed">
            {{ $translator.t('WILL_BE_EMPTY') }}: <b>{{ stock.predictWhenStockWillBeEmpty }}</b>
          </p>
          <hr>
          <p>{{ $translator.t('DELIVERY') }}: <b>{{ stock.created }}</b></p>
          <p>{{ $translator.t('NUMBER_OF_UNITS') }}: <b>{{ stock.numberOfUnits }}</b></p>
          <p>{{ $translator.t('USED_UNITS') }}: <b>{{ stock.usedUnits }}</b></p>
          <p v-if="!stock.allUnitsUsed">
            {{ $translator.t('TERMINATED_DATE') }}: <b>{{ stock.terminatedDate }}</b>
          </p>
          <p>
            {{ $translator.t('LAST_INJECTION_FROM_STOCK') }}: <b>{{ stock.lastInjectionFromStock }}</b>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $translator.t('OK') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stock: Object,
  }
}
</script>
