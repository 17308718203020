import { API_BASE_URL } from '@/constants/constants';

async function postStocks(data = {}) {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/stocks', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    return response.json();
}

export default postStocks
