import { API_BASE_URL } from '@/constants/constants';

async function deleteReasons(reasonId) {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/reasons/' + reasonId, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const error = new Error('An error occurred');
        error.response = response;
        throw error;
    }

    // If response is 204 No Content, do not try to parse JSON
    if (response.status === 204) {
        return;
    }

    return response.json();
}

export default deleteReasons;
