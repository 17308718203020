import {reactive} from 'vue';

const loadLocaleMessages = () => {
    const messages = {};

    try {
        const locales = require.context('@/assets/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

        if (locales.keys().length === 0) {
            console.warn("Translation not found");
        }

        locales.keys().forEach((key) => {
            const locale = key.match(/([A-Za-z0-9-_]+)\./i)[1];
            messages[locale] = locales(key);
        });

    } catch (error) {
        console.warn("Translation not found");
    }

    return messages;
};

const getInitialLanguage = () => {
    return localStorage.getItem('user_language') || 'en';
};

const translator = reactive({
    locale: getInitialLanguage(),
    messages: loadLocaleMessages(),
    t(key) {
        return this.messages[this.locale] && this.messages[this.locale][key]
            ? this.messages[this.locale][key]
            : key;
    },
    changeLanguage(newLocale) {
        if (this.messages[newLocale]) {
            this.locale = newLocale;
            localStorage.setItem('user_language', newLocale);
        } else {
            console.warn(`Translation for ${newLocale} not found`);
        }
    }
});

export default translator;
