import { API_BASE_URL } from '@/constants/constants';

async function getUnusedStocks() {
    const token = localStorage.getItem('token');
    const response = await fetch(API_BASE_URL + '/api/unused-stocks', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    return response.json();
}

export default getUnusedStocks
