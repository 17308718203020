<template>
  <div class="modal fade" id="addInjectionModal" tabindex="-1" aria-labelledby="addInjectionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addInjectionModalLabel">{{ $translator.t('NEW_INJECTION') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-2">
            <label for="unitsInput" class="form-label">{{ $translator.t('UNITS') }}</label>
            <input type="number" class="form-control" id="unitsInput" v-model="injection_units">
            <p v-if="errors.injection_units" class="valid-error">{{ errors.injection_units }}</p>
          </div>

          <div class="mb-2">
            <div class="mb-3">
              <button type="button"
                      class="btn btn-link"
                      data-bs-toggle="modal"
                      data-bs-target="#reasonModal">
                {{ $translator.t('REASON') }}
              </button>

              <select class="form-select"
                      aria-label="Reason select"
                      id="reasonInput"
                      @change="handleReasonChange"
                      v-model="injection_reason"
                      :disabled="isDisabled">
                <option v-for="reason in reasons" :value="reason.id" :key="reason.id">
                  {{ reason.title === 'REASON_PREVENTION' ? $translator.t('REASON_PREVENTION') : reason.title }}
                </option>
                <option value="other_reason">{{ $translator.t('OTHER_REASON') }}</option>
              </select>
              <p v-if="errors.injection_reason" class="valid-error">{{ errors.injection_reason }}</p>
            </div>

            <div v-if="injection_other_reason">
              <label for="reasonDescriptionInput" class="form-label">{{ $translator.t('REASON_DESCRIPTION') }}</label>
              <input type="text"
                     class="form-control"
                     id="reasonDescriptionInput"
                     v-model="injection_reason_description">
              <p v-if="errors.injection_reason_description" class="valid-error">
                {{ errors.injection_reason_description }}
              </p>
            </div>
          </div>

          <div class="mb-3">
            <button type="button"
                    class="btn btn-link"
                    @click="goToStock"
                    data-bs-dismiss="modal">
              {{ $translator.t('STOCK') }}
            </button>

            <select class="form-select" aria-label="Stock select" id="stockInput" v-model="injection_stock">
              <option v-for="stock in unusedStocks" :value="stock.id" :key="stock.id">
                [{{ stock.availablePortions }}] {{ stock.company }}
              </option>
            </select>
            <p v-if="errors.injection_stock" class="valid-error">{{ errors.injection_stock }}</p>
          </div>

          <div class="mb-3">
            <label for="dateInput" class="form-label">{{ $translator.t('DATE') }}</label>
            <input type="date" class="form-control" id="dateInput" v-model="injection_date">
            <p v-if="errors.injection_date" class="valid-error">{{ errors.injection_date }}</p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" @click="validateForm" class="btn btn-success">{{ $translator.t('ADD') }}</button>
        </div>
      </div>
    </div>
  </div>

  <ReasonModal
      :reasons="reasons"
      @update-reasons="updateReasons"
  />
</template>

<script>
import ReasonModal from "@/components/Reason/ReasonModal.vue";
import postInjections from "@/composables/injection/postInjections";
import getInjections from "@/composables/injection/getInjections";
import getUnusedStocks from "@/composables/stock/getUnusedStocks";
import getUserDetails from "@/composables/user/getUserDetails";
import {getTodayDate} from "@/utils/getTodayDate";
import {closeModal} from "@/utils/closeModal";

export default {
  components: {ReasonModal},
  props: {
    reasons: Array,
  },
  emits: ['update-injections', 'update-reasons', 'update-messages'],
  data() {
    return {
      unusedStocks: [],
      injection_stock: null,
      injection_units: this.setDefaultSinglePortion(),
      injection_reason: this.setDefaultReason(),
      injection_reason_description: null,
      injection_date: getTodayDate(),
      injection_other_reason: false,
      errors: {},
    }
  },
  methods: {
    updateReasons(updatedReasons) {
      this.$emit('update-reasons', updatedReasons);
    },
    postInjections() {
      postInjections({
        created: this.injection_date,
        numberOfUnits: this.injection_units,
        reasonId: this.injection_reason,
        reasonDescription: this.injection_reason_description,
        stockId: this.injection_stock,
      })
          .then(() => {
            getInjections()
                .then(result => this.$emit('update-injections', result))
                .catch(err => console.log(err.message))
            this.injection_units = this.setDefaultSinglePortion()
            this.injection_reason = this.setDefaultReason()
            this.injection_reason_description = null
            this.injection_stock = null
            this.injection_date = getTodayDate()

            this.$emit('update-messages', this.$translator.t('INJECTION_ADDED'))
          })
          .catch(err => console.log(err.message))
    },
    validateForm() {
      this.errors.injection_stock = null;
      this.errors.injection_units = null;
      this.errors.injection_reason = null;
      this.errors.injection_date = null;

      if (!this.injection_stock) this.errors.injection_stock = this.$translator.t('STOCK_REQUIRE');
      if (!this.injection_units) this.errors.injection_units = this.$translator.t('NUMBER_OF_UNITS_REQUIRE');
      if (!this.injection_reason && !this.injection_reason_description) this.errors.injection_reason = this.$translator.t('REASON_REQUIRE');
      if (!this.injection_date) this.errors.injection_date = this.$translator.t('DATE_REQUIRE');

      if (Object.keys(this.errors).every(key => !this.errors[key])) {
        this.postInjections();
        closeModal('addInjectionModal')
      }
    },
    setDefaultReason() {
      const mainReason = this.reasons.find(reason => reason.isMain);
      if (mainReason) {
        this.injection_reason = mainReason.id;

        return mainReason.id
      }
    },
    setDefaultSinglePortion() {
      getUserDetails()
          .then(result => {
            this.injection_units = result.single_portion;
          })
          .catch(err => console.log(err.message));

      return this.injection_units
    },
    handleReasonChange() {
      this.injection_other_reason = (this.injection_reason === 'other_reason');
    },
    goToStock() {
      this.$router.push({name: 'stock'});
    },
  },
  created() {
    getUnusedStocks()
        .then(result => this.unusedStocks = result)
        .catch(err => console.log(err.message))
  },
  watch: {
    reasons(newReasons) {
      if (newReasons && newReasons.length > 0) {
        this.setDefaultReason();
      }
    }
  },
  computed: {
    isDisabled() {
      if (this.injection_reason_description) {
        return this.injection_reason_description.trim().length > 0;
      }
      return false
    }
  }
}
</script>

<style scoped>
.valid-error {
  color: red
}
</style>
