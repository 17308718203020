import {createStore} from "vuex";
import getUserDetails from "@/composables/user/getUserDetails";
import translator from "@/utils/translator";

export default createStore({
    state: {
        isAuthenticated: !!localStorage.getItem("token"),
        username: localStorage.getItem("username") || "",
        singlePortion: localStorage.getItem("singlePortion") || "",
        frequency: localStorage.getItem("frequency") || "",
        darkmode: localStorage.getItem("darkmode") === "true",
        language: localStorage.getItem("user_language") || "en",
    },
    mutations: {
        setAuthentication(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setSinglePortion(state, singlePortion) {
            state.singlePortion = singlePortion;
        },
        setFrequency(state, frequency) {
            state.frequency = frequency;
        },
        setDarkMode(state, darkmode) {
            state.darkmode = darkmode;
            localStorage.setItem("darkmode", darkmode ? "true" : "false");
        },
        setLanguage(state, language) {
            state.language = language
            localStorage.setItem("user_language", language);
        },
    },
    actions: {
        async login({commit}) {
            try {
                const userDetails = await getUserDetails();

                commit("setAuthentication", true);
                commit("setUsername", userDetails.username);
                commit("setSinglePortion", userDetails.single_portion);
                commit("setFrequency", userDetails.frequency);
                commit("setDarkMode", userDetails.darkmode);
                commit("setLanguage", userDetails.language.toLowerCase());

                localStorage.setItem("username", userDetails.username);
                localStorage.setItem("singlePortion", userDetails.single_portion);
                localStorage.setItem("frequency", userDetails.frequency);
                localStorage.setItem("user_settings_initialized", "true");

                translator.changeLanguage(userDetails.language.toLowerCase());
                document.documentElement.setAttribute(
                    "data-bs-theme",
                    userDetails.darkmode ? "dark" : "light"
                );
            } catch {
            }
        },
        logout({commit}) {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("user_settings_initialized");
            localStorage.removeItem("singlePortion");
            localStorage.removeItem("frequency");

            commit("setAuthentication", false);
            commit("setUsername", "");
            commit("setSinglePortion", "");
            commit("setFrequency", "");

            document.documentElement.setAttribute(
                "data-bs-theme",
                localStorage.getItem("darkmode") === "true" ? "dark" : "light");
        },
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        username: (state) => state.username,
        singlePortion: (state) => state.singlePortion,
        frequency: (state) => state.frequency,
        darkmode: (state) => state.darkmode,
        language: (state) => state.language,
    },
});
