<template>
  <nav aria-label="Pagination" v-if="total_pages > 1">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: current_page === 1 }">
        <button class="page-link" @click="changePage(current_page - 1)" :disabled="current_page === 1">
          {{ $translator.t('PREVIOUS') }}
        </button>
      </li>

      <li class="page-item" :class="{ active: current_page === 1 }">
        <button class="page-link" @click="changePage(1)">1</button>
      </li>

      <li v-if="current_page > 3 && total_pages > 4" class="page-item">
        <span class="page-link">...</span>
      </li>

      <li v-for="page in middlePages" :key="page" class="page-item" :class="{ active: current_page === page }">
        <button class="page-link" @click="changePage(page)">{{ page }}</button>
      </li>

      <li v-if="current_page < total_pages - 2 && total_pages > 4" class="page-item">
        <span class="page-link">...</span>
      </li>

      <li class="page-item" :class="{ active: current_page === total_pages }">
        <button class="page-link" @click="changePage(total_pages)">{{ total_pages }}</button>
      </li>

      <li class="page-item" :class="{ disabled: current_page === total_pages }">
        <button class="page-link" @click="changePage(current_page + 1)" :disabled="current_page === total_pages">
          {{ $translator.t('NEXT') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    current_page: Number,
    total_items: Number,
    items_per_page: Number,
    total_pages: Number,
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.total_pages) {
        this.$emit('change-page', page);
      }
    }
  },
  computed: {
    middlePages() {
      const pages = [];

      if (this.total_pages <= 4) {
        for (let i = 2; i < this.total_pages; i++) {
          pages.push(i);
        }
      } else if (this.current_page <= 3) {
        for (let i = 2; i <= 4 && i < this.total_pages; i++) {
          pages.push(i);
        }
      } else if (this.current_page > 3 && this.current_page < this.total_pages - 2) {
        pages.push(this.current_page - 1, this.current_page, this.current_page + 1);
      } else if (this.current_page >= this.total_pages - 2) {
        for (let i = this.total_pages - 3; i < this.total_pages; i++) {
          pages.push(i);
        }
      }

      return pages;
    }
  },
}
</script>
